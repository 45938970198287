import React, { useState } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { Link } from 'react-router-dom'
import Modal from '../Modal'

function StatusListing({
  status,
  id,
  pushBackTime,
  notes,
  updatedAt,
  showActions = true,
  isAuthenticated = false,
  deleteStatus
}) {
  const [isToggled, setToggled] = useState(false);
  const toggleTrueFalse = () => setToggled(!isToggled);
  const confirmDelete = () => {
    deleteStatus(id);
    setToggled(false);
  }
  return (
    <article className="status__wrapper">
      <div className="status__content">
        <span className="status__time">{moment(updatedAt).format('LT')}</span>
        <span className="status__date">{moment(updatedAt).format('L')}</span>
        <span className="status__status">{status}</span>
        {(status === 'Pushed Back') &&
          <span className="status__push-back">{pushBackTime}</span>
        }
        {(notes.legth !== 0) &&
          <span className="status__notes">{notes}</span>
        }
      </div>
      {isAuthenticated &&
        <nav className={`${showActions ? 'show' : 'hide'}`}>
          <Link className="status__action" to={`/edit-status/${id}`}>Edit</Link>
          <button onClick={toggleTrueFalse} className="status__action">Delete</button>
          {isToggled &&
            <Modal on={isToggled} toggle={toggleTrueFalse}>
              <div className="modal__card">
                <h2 className="heading">Delete this status?</h2>
                <button onClick={confirmDelete} className="btn green">Delete</button>
                <button onClick={toggleTrueFalse} className="btn grey">Cancel</button>
              </div>
            </Modal>
          }
        </nav>
      }
    </article>
  )
}

StatusListing.propTypes = {
  status: PropTypes.string,
  id: PropTypes.string.isRequired,
  pushBackTime: PropTypes.string,
  notes: PropTypes.string,
  updatedAt: PropTypes.string.isRequired,
  showActions: PropTypes.bool,
  isAuthenticated: PropTypes.bool,
  deleteStatus: PropTypes.func
}

export default StatusListing