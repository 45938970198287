import React, { useState, useEffect } from 'react'
import { Link, useParams } from 'react-router-dom'
import axios from 'axios'
import { useForm } from 'react-hook-form'
import { useAuth } from '../../hooks/storeApi.js'

export default function CreateUser() {
  const { id: userId } = useParams();
  const { register, handleSubmit, errors, getValues, reset } = useForm();
  const { token } = useAuth();
  const [message, setMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      // Update fields if we're editing
      if (userId) {
        const userRequest = await axios.get(`/api/auth/user/${userId}`, { headers: { 'Authorization': `Bearer ${token}` } });
        reset({
          firstName: userRequest.data.firstName,
          lastName: userRequest.data.lastName,
          email: userRequest.data.email,
        });
      }
      setIsLoading(false);
    };
    fetchData();
  }, [reset, userId, token]);

  const onSubmit = async (data) => {
    const url = userId ? `/api/auth/user/update/${userId}` : '/api/createUser';
    const verb = userId ? 'update' : 'create';
    const payload = {
      username: data.email,
      ...data,
    }
    const results = await axios.post(url, payload, { headers: { 'Authorization': `Bearer ${token}` } });
    console.log(results);
    if (results.data.user.id) {
      const { user } = results.data;
      setMessage(`Successfully ${verb}d user ${user.firstName} ${user.lastName} (${user.email})`);
      reset();
    } else {
      setMessage(`Could not ${verb} user.`)
    }
  }

  // Loading state
  if (isLoading) return (
    <main>
      <p><em>Loading...</em></p>
    </main>
  )


  return (
    <main>
      {message &&
        <p>{message} <Link to="/">Back to Admin</Link></p>
      }
      <form onSubmit={handleSubmit(onSubmit)}>
        <div>
          <label htmlFor="email">Email</label>
          <input type="text" name="email" ref={register({ required: true })} />
          {errors.email && <p>This field is required</p>}
          <label htmlFor="firstName">First Name</label>
          <input type="text" name="firstName" ref={register({ required: true })} />
          {errors.firstName && <p>This field is required</p>}
          <label htmlFor="lastName">Last Name</label>
          <input type="text" name="lastName" ref={register({ required: true })} />
          {errors.lastName && <p>This field is required</p>}
          <label htmlFor="password">Password</label>
          <input type="password" name="password" ref={register({ required: true })} />
          {errors.password && <p>This field is required</p>}
          <label>Confirm Password </label>
          <input type="password" name="passwordConfirmation" ref={register({
            required: 'Please confirm password!',
            validate: {
              matchesPreviousPassword: (value) => {
                const { password } = getValues();
                return password === value || 'Passwords should match!';
              },
            }
          })} />
          {errors.passwordConfirmation && <p>{errors.passwordConfirmation.message}</p>}
        </div>
        <input className="btn green" type="submit" />
        <Link className="btn grey" to="/manage-users">Cancel</Link>
      </form>

    </main>

  )
}