import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import moment from 'moment'
import PropTypes from 'prop-types'
import Icon from '../Icon'
import Modal from '../Modal'

function AdminJobListing({ id, jobDate, name, city, foreman, user, deleteJob }) {
  const [isToggled, setToggled] = useState(false);
  const toggleTrueFalse = () => setToggled(!isToggled);
  const confirmDelete = () => {
    deleteJob(id);
    setToggled(false);
  }

  return (
    <div className="admin-job-table__listing">
      <span>{moment.utc(jobDate).format('L')} </span>
      <span>{name} </span>
      <span>{city}</span>
      {/* {foreman ? (
        <span>{user.firstName} {user.lastName}</span>
      ) : (
          <span><em>Unassigned</em></span>
        )
      } */}
      {{
        'foreman': <span>{user.firstName} {user.lastName}</span>,
        'company': <span>{user.companyName}</span>
      }[user.role] || <span><em>Unassigned</em></span>}
      <Link className="action-btn" to={`/edit-job/${id}`}>
        <Icon name="edit" />
      </Link>
      <button onClick={toggleTrueFalse} className="action-btn">
        <Icon name="delete" />
      </button>
      {isToggled &&
        <Modal on={isToggled} toggle={toggleTrueFalse}>
          <div className="modal__card">
            <h2 className="heading">Delete this job?</h2>
            <button onClick={confirmDelete} className="btn green">Delete</button>
            <button onClick={toggleTrueFalse} className="btn grey">Cancel</button>
          </div>
        </Modal>
      }
    </div>
  )
}

AdminJobListing.propTypes = {
  id: PropTypes.string,
  jobDate: PropTypes.string,
  name: PropTypes.string,
  city: PropTypes.string,
  user: PropTypes.object,
  deleteJob: PropTypes.func
}

export default AdminJobListing

