import React from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import NavLogin from './NavLogin'
import Logo from '../images/mpm-logo.png'


const Header = ({ pageTitle }) => {
  return (
    <header className="header">
      <div className="logo__wrapper">
        <Link className="logo" to="/">
          <img src={Logo} alt="Michigan Paving & Materials Co." />
        </Link>
      </div>
      <nav className="nav">
        <h1 className="page-title">{pageTitle}</h1>
        <NavLogin />
      </nav>
    </header>
  )
}

Header.propTypes = {
  pageTitle: PropTypes.string
}

export default Header