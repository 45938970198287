import { useStore } from "../store";

export const useAuth = () => {
  const { state, dispatch } = useStore();
  return {
    isAuthenticated: state.isAuthenticated,
    user: state.user,
    token: state.token,
    logout: () => dispatch({ type: "logout" }),
    login: (payload) => dispatch({ type: "login", payload })
  }
}