import React, { Fragment } from 'react'
import { Link } from 'react-router-dom'
import { useAuth } from '../hooks/storeApi';

function Sidebar() {
  const { isAuthenticated, user } = useAuth();
  if (isAuthenticated) return (
    <aside className="sidebar">
      <Link to="/create-status" className="btn green">Create Job Status</Link>
      {(user.role === 'admin') &&
        <Fragment>
          <Link className="btn green" to="/create-job">Create Job</Link>
          <Link className="btn green" to="/manage-jobs">Manage Jobs</Link>
          <Link className="btn green" to="/create-user">Create User</Link>
          <Link className="btn green" to="/manage-users">Manage Users</Link>
        </Fragment>
      }
    </aside>
  )
  return null;
}

export default Sidebar
