import React, { useState, useEffect } from 'react'
import axios from 'axios'
import _ from 'lodash'
import { useAuth } from '../../hooks/storeApi.js'
import AdminJobListing from '../Job/AdminJobListing'

const ManageJobs = () => {
  const { token } = useAuth();
  const [jobs, setJobs] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  // Delete Status
  const deleteJob = async (id) => {
    try {
      const results = await axios.post('/api/auth/job/delete', { id }, { headers: { 'Authorization': `Bearer ${token}` } });
      if (results.status === 204) {
        const newJobs = _.reject(jobs, { id });
        setJobs(newJobs);
      } else {
        throw new Error('Could not delete job');
      }
    } catch (err) {
      console.log(err);
    }
  }

  // Get All Jobs
  useEffect(() => {
    const fetchData = async () => {
      const request = await axios.get('/api/jobs/all');
      setJobs(request.data);
      setIsLoading(false);
    };
    fetchData();
  }, [token]);

  // Load Status
  if (isLoading) return (
    <p><em>Loading...</em></p>
  )

  return (
    <div className="admin-job-table__wrapper">
      <header className="admin-job-table__header">
        <span>Date</span>
        <span>Job</span>
        <span>City</span>
        <span>Foreman</span>
      </header>
      {jobs.length !== 0 ? (
        jobs.map(job => (
          <AdminJobListing key={`job-${job.id}`} {...job} deleteJob={deleteJob} />
        )))
        : (
          <p><em>No jobs at this time.</em></p>
        )
      }
    </div>
  );
};

export default ManageJobs;