import React, { useCallback, useEffect } from 'react'
import Icon from '../components/Icon'
import Portal from './Portal'

const Modal = ({ children, toggle, on }) => {

  // Trigger close on Esc key
  const escFunction = useCallback((event) => {
    if (event.keyCode === 27) { toggle() }
  }, [toggle]);

  // Add event listener to close modal
  useEffect(() => {
    document.addEventListener("keydown", escFunction, false);
    return () => {
      document.removeEventListener("keydown", escFunction, false);
    };
  }, [toggle, escFunction]);

  return (
    <Portal>
      {on && (
        <div className="modal__wrapper">
          <div className="modal__content">
            <button className="modal__close" onClick={toggle}>
              <Icon name="close" />
            </button>
            <div>{children}</div>
          </div>
          <div className="modal__background" onClick={toggle} />
        </div>
      )}
    </Portal>
  );
}

export default Modal;