import React, { Fragment } from 'react'
import { Route, Redirect } from 'react-router-dom';
import { useStore } from '../../store';
import Header from '../Header';
import Sidebar from '../Sidebar';

const SecureLayout = ({ component: Component, pageTitle, ...rest }) => {
  const { state } = useStore();
  if (state.isAuthenticated) return (
    <Route {...rest} render={matchProps => (
      <Fragment>
        <Header pageTitle={pageTitle} />
        <div className="container user">
          <Component {...matchProps} />
          <Sidebar />
        </div>
      </Fragment>
    )} />
  )
  return <Redirect to="/" />;
}

export default SecureLayout;