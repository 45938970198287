import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import Job from '../Job/JobListing'

const ReviewStatus = ({ location }) => {
  const { job, status } = location.state;

  return (
    <main>
      <section className="notification divider">
        <p><em>Thank you posting the following job status. It will be viewable for the next 24 hours<br />
        If you have any questions please contact dispatch.</em></p>
      </section>
      <Job job={job} updates={[status]} lazyLoad={false} showActions={false} />
      <Link to="/" className="btn green">Done</Link>
    </main>
  );
};

ReviewStatus.propTypes = {
  location: PropTypes.object.isRequired
}

export default ReviewStatus
