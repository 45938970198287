import React, { useState, useEffect, Fragment } from 'react'
import axios from 'axios'
import Job from './JobListing'

const JobList = () => {
  const [jobs, setJobs] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const fetchJobs = async () => {
    const request = await axios.get('/api/jobs/withStatus');
    setJobs(request.data);
    setIsLoading(false);
  };

  // const fetchAllJobs = async () => {
  //   const request = await axios.get('/api/jobs/all');
  //   setJobs(request.data);
  //   setIsLoading(false);
  // };

  // Get All Jobs
  useEffect(() => {
    fetchJobs();
  }, []);

  // Loading View
  if (isLoading) return (
    <p><em>Loading...</em></p>
  );

  // No jobs found
  if (jobs.length === 0) return (
    <p><em>No job status at this time.</em></p>
  )

  return (
    <Fragment>
      {jobs.map(job => (
        <Job key={`job-${job.id}`} job={job} />
      ))}
    </Fragment>
  );
};

export default JobList