import React, { useState, Fragment } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import axios from 'axios'
import Status from '../Job/StatusListing'
import Icon from '../Icon'
import { useAuth } from '../../hooks/storeApi.js'

function JobListing({
  lazyLoad = true,
  updates = [],
  job,
  showActions = true
}) {
  const { isAuthenticated, token } = useAuth();
  const [statuses, setStatuses] = useState(updates);
  const [isLoading, setIsLoading] = useState(false);
  const [isToggled, setToggled] = useState(!lazyLoad);

  // Get Statuses
  const fetchStatuses = async () => {
    if (isToggled) {
      setToggled(!isToggled);
    } else {
      setIsLoading(true);
      setToggled(true);
      const request = await axios.get(`/api/statuses/${job.id}`);
      setStatuses(request.data);
      setIsLoading(false);
    }
  };

  // Delete Status
  const deleteStatus = async (id) => {
    try {
      const results = await axios.post('/api/auth/status/delete', { id }, { headers: { 'Authorization': `Bearer ${token}` } });
      if (results.status === 204) {
        const newStatuses = _.reject(statuses, { id });
        setStatuses(newStatuses);
      } else {
        throw new Error('Could not delete status');
      }
    } catch (err) {
      console.log(err);
    }
  }

  return (
    <div className={`job__wrapper ${isToggled ? 'open' : 'collapsed'}`}>
      <header className="job__header">
        <h3 className="job__title">{job.name} - <span className="job__type">{job.type}</span></h3>
        <span className="job__city">{job.city}</span>
        {{
          'foreman': <span>{job.user.firstName} {job.user.lastName}</span>,
          'company': <span>{job.user.companyName}</span>
        }[job.user.role] || <span><em>Unassigned</em></span>}
        {lazyLoad &&
          <button onClick={fetchStatuses} className="toggle-btn">
            <Icon name="arrow-down" />
          </button>}
      </header>
      <section className="statuses">
        <h2 className="heading">Status</h2>
        {isLoading ? (
          <p><em>Loading status updates...</em></p>
        ) : (
            <Fragment>
              {statuses.length === 0 ? (
                <p><em>No status updates for this job at this time.</em></p>
              ) : (
                  <Fragment>
                    {statuses.map(status => <Status key={status.id} {...status} showActions={showActions} deleteStatus={deleteStatus} isAuthenticated={isAuthenticated} />)}
                  </Fragment>
                )}
            </Fragment>
          )}
      </section>
    </div>
  )
}

JobListing.propTypes = {
  statuses: PropTypes.array,
  job: PropTypes.object,
  lazyLoad: PropTypes.bool,
  showActions: PropTypes.bool
}

export default JobListing

