import React, { Fragment } from 'react'
import { Route } from 'react-router-dom';
import Header from '../../components/Header';
import Home from '../pages/Home';
import Sidebar from '../Sidebar';

const MainLayout = ({ component: Component, ...rest }) => {
  // const { state } = useStore();
  // if (state.isAuthenticated && state.user.role === 'admin') return (
  //   <Route {...rest} render={matchProps => (
  //     <Fragment>
  //       <Header pageTitle="Job Status Admin" />
  //       <div className="container user">
  //         <Home {...matchProps} />
  //       </div>
  //     </Fragment>
  //   )} />
  // )
  return (
    <Route {...rest} render={matchProps => (
      <Fragment>
        <Header pageTitle="Job Status" />
        <div className="container user">
          <Home {...matchProps} />
          <Sidebar />
        </div>
      </Fragment>
    )} />
  );
}

export default MainLayout;