import React, { useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import axios from 'axios'
import jwt from 'jsonwebtoken'

export default function ResetPassword() {
  const { token } = useParams();
  const decoded = jwt.decode(token);
  const [message, setMessage] = useState(null);
  const { register, handleSubmit, errors, getValues } = useForm();
  const invalidLink = !decoded || decoded.exp * 1000 < new Date().getTime();

  const onSubmit = async (data) => {
    const url = '/api/auth/resetPassword';
    const results = await axios.post(url, { newPassword: data.password }, { headers: { 'Authorization': `Bearer ${token}` } });
    if (results.data.message) {
      setMessage(results.data.message);
    };
  }

  if (invalidLink) {
    return (
      <main>
        <p><em>This link has expired, <Link to="/forgot-password">please click here to request a new one.</Link></em></p>
      </main>
    )
  }

  if (message) {
    return (
      <main>
        <p>{message}</p>
      </main>
    )
  }

  return (
    <main>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div>
          <label htmlFor="password">New Password:</label>
          <input type="password" name="password" ref={register({ required: true })} />
          {errors.password && <p>This field is required</p>}
          <label>Confirm New Password: </label>
          <input type="password" name="passwordConfirmation" ref={register({
            required: 'Please confirm password!',
            validate: {
              matchesPreviousPassword: (value) => {
                const { password } = getValues();
                return password === value || 'Passwords do not match.';
              },
            }
          })} />
          {errors.passwordConfirmation && <p>{errors.passwordConfirmation.message}</p>}
        </div>
        <input className="btn green" type="submit" />
      </form>
    </main>
  )
}
