import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import Icon from '../Icon'
import Modal from '../Modal'

function AdminUserListing({ id, firstName, lastName, email, deleteUser }) {
  const [isToggled, setToggled] = useState(false);
  const toggleTrueFalse = () => setToggled(!isToggled);
  const confirmDelete = () => {
    deleteUser(id);
    setToggled(false);
  }

  return (
    <div className="admin-user-table__listing">
      <span>{lastName}</span>
      <span>{firstName}</span>
      <span>{email}</span>
      <Link className="action-btn" to={`/edit-user/${id}`}>
        <Icon name="edit" />
      </Link>
      <button onClick={toggleTrueFalse} className="action-btn">
        <Icon name="delete" />
      </button>
      {isToggled &&
        <Modal on={isToggled} toggle={toggleTrueFalse}>
          <div className="modal__card">
            <h2 className="heading">Delete this user?</h2>
            <button onClick={confirmDelete} className="btn green">Delete</button>
            <button onClick={toggleTrueFalse} className="btn grey">Cancel</button>
          </div>
        </Modal>
      }
    </div>
  )
}

AdminUserListing.propTypes = {
  id: PropTypes.string,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  email: PropTypes.string,
  deleteUser: PropTypes.func
}

export default AdminUserListing

