import React, { useState } from 'react'
import axios from 'axios'
import { useForm } from 'react-hook-form'

export default function ForgotPassword() {
  const { register, handleSubmit, errors, reset } = useForm();
  const [message, setMessage] = useState(null);

  const onSubmit = async (data) => {
    const url = '/api/forgotPassword';
    const results = await axios.post(url, { email: data.email });
    if (results.data.message) {
      setMessage(results.data.message);
      reset();
    };
  }

  return (
    <main>
      <p>Enter your email to reset your password</p>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div>
          <p>{message}</p>
          <label htmlFor="email">Email</label>
          <input type="text" name="email" placeholder="Email Address" ref={register({ required: true })} />
          {errors.email && <p>This field is required</p>}
        </div>
        <input className="btn green" type="submit" />
      </form>
    </main>
  )
}
