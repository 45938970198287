import React, { useState, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { Link, useHistory, useParams } from 'react-router-dom'
import _ from 'lodash'
import axios from 'axios'
import { useAuth } from '../../hooks/storeApi.js'

export default function CreateStatus() {
  const { id: statusId } = useParams();
  const history = useHistory();
  const { token, user } = useAuth();
  const { handleSubmit, register, errors, watch, reset, setValue } = useForm();
  const [data, setData] = useState({ jobs: null, foremen: null, companies: null });
  const [isLoading, setIsLoading] = useState(true);
  const status = watch('status');
  const jobId = watch('jobId');

  // Sets foreman to current foreman when job is selected
  const setJobDefaults = () => {
    const currentJob = _.find(data.jobs, function (o) { return o.id === jobId; });
    const { foreman, type } = currentJob;
    reset({ foreman, type, jobId });
  }
  // Empty Push Back Time if Status changed to "Hold" or "Canceled"
  const clearPushBack = () => {
    setValue([
      { pushBackTime: undefined },
      { status },
    ])
  }

  useEffect(() => {
    // Get all the available jobs and foremen
    const fetchData = async () => {
      const foremenRequest = await axios.get('/api/auth/foremen', { headers: { 'Authorization': `Bearer ${token}` } });
      const companyRequest = await axios.get('/api/auth/companies', { headers: { 'Authorization': `Bearer ${token}` } });
      const jobRequest = await axios.get('/api/jobs/all');

      // Update fields if we're editing
      if (statusId) {
        const statusRequest = await axios.get(`/api/auth/status/${statusId}`, { headers: { 'Authorization': `Bearer ${token}` } });
        reset({
          jobId: statusRequest.data.jobId,
          status: statusRequest.data.status,
          notes: statusRequest.data.notes,
          pushBackTime: statusRequest.data.pushBackTime,
          foreman: statusRequest.data.job.foreman,
          type: statusRequest.data.job.type,
        });
      }

      setData({ jobs: jobRequest.data, foremen: foremenRequest.data, companies: companyRequest.data });
      setIsLoading(false);
    };
    fetchData();
  }, [reset, statusId, token]);

  const onSubmit = async (data) => {
    // Decide update/create route based on statusId existing
    const url = statusId ? `/api/auth/status/update/${statusId}` : '/api/auth/status/create';
    const createdBy = user.id;
    const payload = {
      createdBy,
      ...data,
    }
    const results = await axios.post(url, payload, { headers: { 'Authorization': `Bearer ${token}` } });
    // Let's review!
    if (results.status === 201) {
      const { job, status } = results.data;
      history.push("/review-status", { job, status });
    }
  };

  // Loading state
  if (isLoading) return (
    <main>
      <p><em>Loading...</em></p>
    </main>
  )

  return (
    <main>
      <form autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
        <select onChange={setJobDefaults} className="select" name="jobId" required ref={register({ required: true })} defaultValue>
          <option disabled value hidden>Select Job</option>
          {data.jobs.map(job => (
            <option key={`job-${job.id}`} value={job.id}>{job.name}</option>
          ))}
        </select>
        {errors.jobId && <span className="form-error">Please select a job</span>}
        <select disabled={(!jobId ? true : null)} className="select" name="type" required ref={register({ required: true })} defaultValue>
          <option disabled value hidden>Job Type</option>
          <option value="Milling">Milling</option>
          <option value="Paving">Paving</option>
        </select>
        {errors.type && <span className="form-error">Please select a job type</span>}
        <select disabled={(!jobId ? true : null)} className="select" name="foreman" required ref={register({ required: true })} defaultValue>
          <option disabled value hidden>Foreman</option>
          {data.foremen.map(foreman => (
            <option key={`foreman-${foreman.id}`} value={foreman.id}>{foreman.lastName}, {foreman.firstName}</option>
          ))}
          <option disabled>- Companies -</option>
          {data.companies.map(company => (
            <option key={`foreman-${company.id}`} value={company.id}>{company.companyName}</option>
          ))}
        </select>
        {errors.foreman && <span className="form-error">Please select a foreman</span>}
        <select disabled={(!jobId ? true : null)} onChange={clearPushBack} className="select" name="status" ref={register} defaultValue>
          <option value hidden>Select Status</option>
          <option value="Pushed Back">Pushed Back</option>
          <option value="Hold">Hold</option>
          <option value="Canceled">Canceled</option>
        </select>
        {status === "Pushed Back" && (
          <select className="select" name="pushBackTime" ref={register} defaultValue>
            <option disabled value="" hidden>Select Push Back Time from Original Start Time</option>
            <option value="15 Minutes from original start time">15 Minutes from original start time</option>
            <option value="30 Minutes from original start time">30 Minutes from original start time</option>
            <option value="45 Minutes from original start time">45 Minutes from original start time</option>
            <option value="1 Hour from original start time">1 Hour from original start time</option>
            <option value="1 Hour and 15 Minutes from original start time">1 Hour and 15 Minutes from original start time</option>
            <option value="1 Hour and 30 Minutes from original start time">1 Hour and 30 Minutes from original start time</option>
            <option value="1 Hour and 45 Minutes from original start time">1 Hour and 45 Minutes from original start time</option>
            <option value="2 Hours from original start time">2 Hours from original start time</option>
            <option value="2 Hours and 15 Minutes from original start time">2 Hours and 15 Minutes from original start time</option>
            <option value="2 Hours and 30 Minutes from original start time">2 Hours and 30 Minutes from original start time</option>
            <option value="2 Hours and 45 Minutes from original start time">2 Hours and 45 Minutes from original start time</option>
            <option value="3 Hours from original start time">3 Hours from original start time</option>
            <option value="3 Hours and 15 Minutes from original start time">3 Hours and 15 Minutes from original start time</option>
            <option value="3 Hours and 30 Minutes from original start time">3 Hours and 30 Minutes from original start time</option>
            <option value="3 Hours and 45 Minutes from original start time">3 Hours and 45 Minutes from original start time</option>
            <option value="4 Hours from original start time">4 Hours from original start time</option>
          </select>
        )}
        <textarea name="notes" placeholder="Additional Notes" ref={register}></textarea>
        <small className="optional">Optional</small>
        <div>
          <input className="btn green" type="submit" />
          <Link to="/" className="btn grey">Cancel</Link>
        </div>
      </form>
    </main>
  )
}
