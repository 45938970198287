import React from 'react'
import JobList from '../Job/JobList'

const Home = () => {
  return (
    <main>
      <JobList />
    </main>
  );
};

export default Home;