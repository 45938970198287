import React, { Component } from 'react'
import {
  BrowserRouter as Router,
  Switch
} from 'react-router-dom'
import { StoreProvider } from './store'

//Import Styles
import './styles/App.scss'

//Layout Route Wrappers
import BasicLayout from './components/layouts/BasicLayout'
import MainLayout from './components/layouts/MainLayout'
import SecureLayout from './components/layouts/SecureLayout'
import AdminLayout from './components/layouts/AdminLayout'

//Pages
import CreateStatus from './components/pages/CreateStatus'
import ReviewStatus from './components/pages/ReviewStatus'
import CreateJob from './components/pages/CreateJob'
import ManageJobs from './components/pages/ManageJobs'
import CreateUser from './components/pages/CreateUser'
import ManageUsers from './components/pages/ManageUsers'
import ForgotPassword from './components/pages/ForgotPassword'
import ResetPassword from './components/pages/ResetPassword'


class App extends Component {
  render() {
    return (
      <StoreProvider>
        <Router>
          <Switch>
            <MainLayout path="/" exact />
            <BasicLayout path="/forgot-password" component={ForgotPassword} pageTitle="Forgot Password" />
            <BasicLayout path="/reset/:token" component={ResetPassword} pageTitle="Reset Password" />
            <SecureLayout path="/create-status" component={CreateStatus} pageTitle="Create Job Status" />
            <SecureLayout path="/edit-status/:id" component={CreateStatus} pageTitle="Edit Job Status" />
            <SecureLayout path="/review-status" component={ReviewStatus} pageTitle="Review Job Status" />
            <AdminLayout path="/create-job" component={CreateJob} pageTitle="Create Job" />
            <AdminLayout path="/edit-job/:id" component={CreateJob} pageTitle="Edit Job" />
            <AdminLayout path="/create-user" component={CreateUser} pageTitle="Create User" />
            <AdminLayout path="/edit-user/:id" component={CreateUser} pageTitle="Edit User" />
            <AdminLayout path="/manage-users" component={ManageUsers} pageTitle="Manage Users" />
            <AdminLayout path="/manage-jobs" component={ManageJobs} pageTitle="Manage Jobs" />
          </Switch>
        </Router>
      </StoreProvider>
    );
  }
}

export default App;