import React, { useState, useEffect } from 'react'
import axios from 'axios'
import _ from 'lodash'
import { useAuth } from '../../hooks/storeApi.js'
import AdminUserListing from '../Users/AdminUserListing'

const AdminHome = () => {
  const { token } = useAuth();
  const [users, setUsers] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  // Delete Status
  const deleteUser = async (id) => {
    try {
      const results = await axios.post('/api/auth/user/delete', { id }, { headers: { 'Authorization': `Bearer ${token}` } });
      if (results.status === 204) {
        const newUsers = _.reject(users, { id });
        setUsers(newUsers);
      } else {
        throw new Error('Could not delete user');
      }
    } catch (err) {
      console.log(err);
    }
  }

  // Get All Jobs
  useEffect(() => {
    const fetchData = async () => {
      const request = await axios.get('/api/auth/foremen', { headers: { 'Authorization': `Bearer ${token}` } });
      setUsers(request.data);
      setIsLoading(false);
    };
    fetchData();
  }, [token]);

  // Load Status
  if (isLoading) return (
    <p><em>Loading...</em></p>
  )

  return (
    <div className="admin-user-table__wrapper">
      <header className="admin-user-table__header">
        <span>Last Name</span>
        <span>First Name</span>
        <span>Email</span>
      </header>
      {users.length !== 0 ? (
        users.map(user => (
          <AdminUserListing key={`user-${user.id}`} {...user} deleteUser={deleteUser} />
        ))) :
        (
          <p><em>No foremen available.</em></p>
        )}
    </div>
  );
};

export default AdminHome;