import React, { Fragment } from 'react'
import { Route } from 'react-router-dom';
import Header from '../../components/Header';

const BasicLayout = ({ component: Component, pageTitle, ...rest }) => {
  return (
    <Route {...rest} render={matchProps => (
      <Fragment>
        <Header pageTitle={pageTitle} />
        <div className="container user">
          <Component {...matchProps} />
        </div>
      </Fragment>
    )} />
  );
}

export default BasicLayout;