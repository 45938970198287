import React, { useState, useEffect } from 'react'
import { useForm, Controller } from 'react-hook-form'
import { Link, useHistory, useParams } from 'react-router-dom'
import DatePicker from 'react-datepicker'
import axios from 'axios'
import moment from 'moment'
import { useAuth } from '../../hooks/storeApi.js'

export default function CreateJob() {
  const { id: jobId } = useParams();
  const history = useHistory();
  const { token, user } = useAuth();
  const { control, handleSubmit, register, errors, reset } = useForm();
  const [foremen, setForemen] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [isLoading, setIsLoading] = useState(true);


  useEffect(() => {
    const fetchData = async () => {
      const foremenRequest = await axios.get('/api/auth/foremen', { headers: { 'Authorization': `Bearer ${token}` } });
      const companiesRequest = await axios.get('/api/auth/companies', { headers: { 'Authorization': `Bearer ${token}` } });

      // Update fields if we're editing
      if (jobId) {
        const jobRequest = await axios.get(`/api/auth/job/${jobId}`, { headers: { 'Authorization': `Bearer ${token}` } });
        const compensatedDate = moment.utc(jobRequest.data.jobDate).format('l LT');
        reset({
          date: new Date(compensatedDate),
          name: jobRequest.data.name,
          city: jobRequest.data.city,
          foreman: jobRequest.data.foreman,
          type: jobRequest.data.type,
        });
      }

      setForemen(foremenRequest.data);
      setCompanies(companiesRequest.data);
      setIsLoading(false);
    };
    fetchData();
  }, [reset, jobId, token]);

  const onSubmit = async (data) => {
    const url = jobId ? `/api/auth/job/update/${jobId}` : '/api/auth/job/create';
    const { date, ...formData } = data;
    const createdBy = user.id;
    const jobDate = moment.utc(date).format('YYYY-MM-DD');
    const payload = {
      createdBy,
      jobDate,
      ...formData
    }
    const results = await axios.post(url, payload, { headers: { 'Authorization': `Bearer ${token}` } });
    if (results.status === 201) {
      history.push("/");
    }
  };
  return isLoading ? (
    <main>
      <p><em>Loading...</em></p>
    </main>
  ) : (
      <main>
        <form autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
          <Controller
            as={DatePicker}
            control={control}
            rules={{ required: true }}
            valueName="selected"
            onChange={([selected]) => selected}
            name="date"
            className="input"
            placeholderText="Select Job Date"
            todayButton="Today" />
          {errors.date && <span className="form-error">Please select a date</span>}
          <input name="name" type="text" ref={register({ required: true })} placeholder="Enter Job Name" />
          {errors.name && <span className="form-error">Please enter a job name</span>}
          <select className="select" name="type" required ref={register({ required: true })} defaultValue>
            <option disabled value hidden>Job Type</option>
            <option value="Milling">Milling</option>
            <option value="Paving">Paving</option>
          </select>
          {errors.type && <span className="form-error">Please select a job type</span>}
          <input name="city" type="text" ref={register({ required: true })} placeholder="Enter City" />
          {errors.city && <span className="form-error">Please enter a city</span>}
          <select className="select" name="foreman" required ref={register({ required: true })} defaultValue>
            <option disabled value hidden>Foreman</option>
            {foremen.map(foreman => (
              <option key={`foreman-${foreman.id}`} value={foreman.id}>{foreman.lastName}, {foreman.firstName}</option>
            ))}
            <option disabled>- Companies -</option>
            {companies.map(company => (
              <option key={`foreman-${company.id}`} value={company.id}>{company.companyName}</option>
            ))}
          </select>
          {errors.foreman && <span className="form-error">Please select a foreman</span>}
          <div>
            <input className="btn green" type="submit" />
            <Link to="/" className="btn grey">Cancel</Link>
          </div>
        </form>
      </main>
    )
}
