import React, { useState, Fragment } from 'react'
import { Link, useHistory } from 'react-router-dom'
import axios from 'axios'
import { useForm } from 'react-hook-form'
import { useAuth } from '../hooks/storeApi.js'

export default function NavLogin() {
  const { login, logout, isAuthenticated } = useAuth();
  const [isToggled, setToggled] = useState(false);
  const history = useHistory();
  const toggleTrueFalse = () => setToggled(!isToggled);
  const { register, handleSubmit } = useForm();
  const onSubmit = async (data) => {
    const loginRequest = await axios.post('/api/login', data);
    if (loginRequest.data.user.id) {
      login(loginRequest.data);
      setToggled(false);
      history.push('/');
    }
  }

  return (
    <Fragment>
      <div className={`login__wrapper ${isToggled ? 'active' : ''}`}>
        {isAuthenticated ? (
          <button onClick={() => logout()} className="login-out-btn">Sign Out</button>
        ) : (
            <button onClick={toggleTrueFalse} className="login-out-btn">Sign In</button>
          )}
        <div className="login__form">
          <form onSubmit={handleSubmit(onSubmit)}>
            <input type="text" name="email" placeholder="Username" ref={register({ required: true })} />
            <input type="password" placeholder="Password" name="password" ref={register({ required: true })} />
            <input className="btn green" type="submit" value="Sign In" />
            <Link onClick={toggleTrueFalse} to="/forgot-password">Forgot Password?</Link>
          </form>
        </div>
      </div>
      <button onClick={toggleTrueFalse} className={`login__shade ${isToggled ? 'active' : ''}`} />
    </Fragment>
  )
}
